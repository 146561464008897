const APPLE_PAY_API_VERSION = 3
export class ApplePaySession {

  constructor(options = {}){
    this.request = {
      countryCode: options.country_code,
      currencyCode: options.currency_code,
      merchantCapabilities: ["supports3DS"],
      supportedNetworks: ["visa", "masterCard", "amex", "discover"],
      total: {label: "Drivu", type: "final", amount: options.payment_amount}
    }
    // this.afterValidateMerchant = options.onValidateMerchant;
    this.onPaymentAuthorized = options.onPaymentAuthorized;
    this.onCancelled = options.onCancelled;
    this.onPaymentIsNotAvailable = options.onPaymentIsNotAvailable || function(e){ alert('onPaymentIsNotAvailable')}
  }

  createSession(){
    if ( !window.ApplePaySession ) {
      this.onPaymentIsNotAvailable();
      return;
    }
    var _this = this;    

    try {
      var session = new window.ApplePaySession(APPLE_PAY_API_VERSION, this.request);
    } catch (error) {
      alert(error);
    }
    
    session.onvalidatemerchant = event => {
      $('.results').append('<p>onvalidatemerchant</p>');
      _this._validateMerchantRequest(event.validationURL, function(merchantSession){
        $('.results').append(`<p>merchantSession ${merchantSession}</p>`);
        session.completeMerchantValidation(merchantSession);
        // _this.afterValidateMerchant();
      });
    }

    session.onpaymentauthorized = event => {
      $('.results').append("<p>Payment authorized:</p>");

      session.completePayment({"status": window.ApplePaySession.STATUS_SUCCESS});
      _this.onPaymentAuthorized(event.payment);
    }
            
    session.oncancel = event => {
      $('.results').append('<p>session cancelled</p>');
    }

    try{
      session.begin();
    } catch(e) {
      $('.results').append(`<li>${ JSON.stringify(e.message) }</li>`);
      $('.results').append(`<li> error on begin ${JSON.stringify(e)} </li>`);
    }
  }

  _validateMerchantRequest(url, cb){    
    $.ajax({
      url: '/moderator/apple_pay_session_validations.json',
      method: 'post',
      data: {apple_pay: {validation_url: url} },
      dataType: "JSON",
      success: function(data, status, xhr){
        cb(data.session);
      },
      errors: function(status, errors){
        $('.results').append(`_validateMerchantRequest ${errors}`)
      }
    });
  }
}
import "dropzone"
var toastr = require('toastr');
import "../utilits/extra"
import { ExtraView } from "../utilits/extra";
import * as globalFun from '../utilits/drivu_global_functions'
Dropzone.autoDiscover = false;
Dropzone.options.myAwesomeDropzone = false;
const extra_template = require("../templates/extra.ejs");

import { PickUpHourView } from "./moderator/pickup_hour_view";
export class BranchMenuExtraView{

  constructor(options){
    this.menu_item_ad = options.menu_item_ad;
    this.menu_item_id = options.menu_item_id;
    this.clone_extra_url = options.clone_extra_url;
    this.extraAttributes = options.extraAttributes;
    this.optionAttributes = options.optionAttributes
    this.shopId = options.shop_id;
    this.branchId = options.branch_id;
    this.extras = this.setExtras(options.extras); 
    this.bindListeners();
  }


  bindListeners(){
    var _this = this;
    
    $('.preparation-time-form').on("ajax:success", function(){
      toastr.success("Update");
    });

    this.bindOnElementInserted(_this);

    $('.add-new-extra').on('click', function(e) {
      $(".extras").append(extra_template({extraIndex: $('.extra').length + 1}));
      e.preventDefault();
    }.bind(this));

    $(document).on("keypress", function(e){
      if(e.key === 'Enter') {
        $('.save-all-extras').trigger('click');
        e.preventDefault();
      }  
    });

    $('.save-all-extras').on('click', function(e){
      e.preventDefault();
      globalFun.showLoadingWheel();
      async.waterfall(_.map(_this.extras, function(extraView){
        return extraView.submit.bind(extraView);
      }), function(){ 
        globalFun.hideLoadingWheel();
      });
    });

    this.bindCloneExtra(this);
    this.bindSortable();
    this.bindAddItemTotag();
    this.bindRemoveItemTag();
    this.bindUploadMenuItemAdVideo();
  }

  onUpdatePreOrder(daysOfWeek){
    var _this = this;

    $('#update-menu-item-attributes').on('ajax:error', function(args){
      if($(this).find('#menu_item_pre_order').is(":checked")){
        _this._showPickUpHoursModel(daysOfWeek); 
      }
    });

    $('#pickupHourModal').find('form').on('ajax:success', function(args){
      $(this).find('#menu_item_pre_order').attr('checked', true);
      $('#update-menu-item-attributes').trigger('submit');
      $(".edit-branch-path").removeClass("d-none");
      // window.location.reload();
      $('#pickupHourModal').modal("hide");

    });
   
  }

  extraViewParams(){
    return {branchId: this.branchId, shopId: this.shopId, menuItemId: this.menu_item_id, optionAttributes: this.optionAttributes }
  }

  bindOnElementInserted(_this){
    globalFun.onElementInserted('body', '.extra-new', function(element) {
      var extraViewParams = _this.extraViewParams();
      extraViewParams.element = element;
      extraViewParams.isNewRecord = true;
      extraViewParams.extra = _this.extraAttributes;
      var extraView = new ExtraView(extraViewParams);
      // make new from extra options if its defiend
      $(element).find('.name').trigger("focus");
      $(element).get(0).scrollIntoView();
      _this.extras.push(extraView);
    });
  }

  setExtras(extras){
    return _.map(extras, function(extra){
      var extraViewParams = this.extraViewParams();
      extraViewParams.element = $(`#extra-element-${extra.id}`);
      extraViewParams.extra = extra;
      extraViewParams.isNewRecord = false;
      extraViewParams.extraOptions = extra.extraOptions;
      extraViewParams.optionAttributes.extra_id = extra.id;
      return new ExtraView(extraViewParams);
    }.bind(this))
  }

  bindCloneExtra(_this){
    $('.copy_extra').on('change',function() {
      var extra_id = $(this).val();
      var menu_item_id = _this.menu_item_id
      if(extra_id) {
        $.ajax({
          type: 'POST',
          url: `${_this.clone_extra_url}` ,
          data: {"extra_id": extra_id, update_by_reference: $("#update_by_reference").is(":checked")},
          success: function(data){
            window.location.reload();
          }
        });
      }
    });
  }
  
  bindSortable(){
    $('*[data-role=extras-active-record-sortble]').sortable({
      items: " > .extra-row",
      stop: function(event, ui){
        var items = $(this).children(".extra-row");
        var position = items.index(ui.item);
        $.ajax({
          url: ui.item.attr("data-move-url"),
          data: {position: position},
          method: "POST",
          dataType: "JSON"
        });
      }   
    });
  }

  bindAddItemTotag(){
    $('.addItemToTag').on('click', function(e){
      var itemInfo = $(e.currentTarget).data()
      $.ajax({
        url: `/admins/branches/${itemInfo.branchId}/menu_item_branch_tags/${itemInfo.menuItemId}`,
        method: "PATCH",
        data: {
          tag: itemInfo.tag
        },
        dataType: "JSON",
        success: function(){
          window.location.reload();
        },
        errors: function(){}
      })
    });
  
  }

  bindRemoveItemTag(){
    $('.removeItemTag').on('click', function(e){
      var itemInfo = $(e.currentTarget).data();
      $.ajax({
        url: `/admins/branches/${itemInfo.branchId}/menu_item_branch_tags/${itemInfo.menuItemId}`,
        method: "DELETE",
        data: {
          tag: itemInfo.tag
        },
        dataType: "JSON",
        success: function(){
          $(e.currentTarget).parents('.item-tag-con').remove();
        }, 
        errors: function(){}
      })
    });
  }

  bindUploadMenuItemAdVideo(){

    $("#update-menu-item-ad-video").dropzone({
      maxFiles:1,
      maxFilesize: 40, // MB
      addRemoveLinks: true,
      dictRemoveFile: "Delete",
      dictCancelUploadConfirmation: "Are you sure to cancel upload?",
      dictDefaultMessage: "Drop files here or click to upload.",
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      init: function () {

        var thisDropzone = this;
        var data = [];
        if (data == null) {
          return;
        }

        var allUploadsSuccessful = true;

        _.each(data, function (file) {
          thisDropzone.emit("addedfile", file);
          thisDropzone.options.thumbnail.call(thisDropzone, file, file.url);
          thisDropzone.emit("complete", file);
        });

        this.on('error', function (file, errorMessage) {
          allUploadsSuccessful = false;
          // Show error message or do something with the errorMessage
          alert("Upload failed: " + errorMessage);
        });
    
        this.on('complete', function (file) {
          if (file.status === Dropzone.SUCCESS) {
            alert('File uploaded successfully!');
          }    
        });

        this.on('queuecomplete', function () {
          if (allUploadsSuccessful) {
            // Reload the page only if all files uploaded successfully
            // window.location.reload();
          } else {
            alert("Some files failed to upload. Please check the errors.");
          }
        });

      }
    });
  }

  _showPickUpHoursModel(daysOfWeek){
    $('.pickup-hours').empty();
    var branchPickUpHourView = new PickUpHourView({
      containerElement: $('#menu-extra-pickup-hours'),
      branchFormId:  `#branch-form-${this.branchId}`,
      branchOnHoursFiledId: `${this.branchId}-branch-pickup-hours`,
      branch_id: this.branchId,
      dayElementClassName: '.singleDay',
      on_hours: [], 
      daysOfWeek: daysOfWeek,
      workingHourContainerClassName: '.pickup-hours',
    });

    branchPickUpHourView.delegateEvents();
    branchPickUpHourView.renderUI();
    branchPickUpHourView.appendBranchOnHoursHiddenFiled();
    branchPickUpHourView.setCurrentWorkingHours();

    branchPickUpHourView.bindOnSubmit(function(e){
      $(e.currentTarget).find(`#${branchPickUpHourView.branchOnHoursFiledId}`).val(JSON.stringify(branchPickUpHourView.serializeWorkingHours()));
      return true;
    });

    $('#pickupHourModal').modal("show");
    
  }
}


export class ScrollablePagination {
  constructor(options) {
    this.currentPage = options.currentPage || 1;
    this.totalPages = options.totalPages || 1;
    this.perPage = options.perPage || 30;
    this.loadMoreCallback = options.loadMoreCallback;
    this.isLoading = false;
    this.container = $(options.container || window);
    
    this.initScrollListener();
  }

  initScrollListener() {
    // Bind the scroll listener to the specified container
    this.container.on('scroll', () => {
      const scrollPosition = this.container.scrollTop() + this.container.height();
      const containerHeight = this.container[0].scrollHeight;

      if (scrollPosition >= containerHeight - 50 && !this.isLoading) {
        this.loadNextPage();
      }
    });
  }

  loadNextPage() {
    if (this.currentPage < this.totalPages) {
      this.isLoading = true;
      this.currentPage++;
      this.loadMoreCallback(this.currentPage, this.perPage, () => {
        this.isLoading = false;
      });
    }
  }
}
